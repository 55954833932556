<template>
	<div class="container-fluid login_header">
		<div class="logo">
			<img src="/images/login_logo.png" alt="" class="img-fluid">
		</div>
		<a href="javascript:void(0);" class="text-decoration-none" @click="openContactUsModel"><div class="contact_btn fs-18">Contact US</div></a>
	</div>
	<section class="pb-40 sign-up-section">
		<div class="container h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col-12 col-md-8 col-lg-6 col-xl-6">
					<div class="box">
						<div class="box-body p-20 custom-form">
							<h3 class="main_heading mb-40 l-h-n">Let’s create your account.</h3>
							<p class="fw-300 l-h-n mb-30">Fill out the form below to start your <span class="fs-18 fw-600">Free 14 Days Trial !</span></p>
							<p class="fs-20 fw-600 mb-20 l-h-n">Clinic’s Contact Representative</p>
							<div class="input_box mb-15">
								<input type="text" required class="form-control form-control-lg" v-model="form.full_name" autocomplete="off" />
								<label class="form-label">Full Name (First & Last Name)</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.full_name?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.full_name">
									{{ errors.full_name[0] }}
								</div>
							</div>
							<div class="input_box mb-15">
								<input type="text" required class="form-control form-control-lg" v-model="form.email" autocomplete="off" />
								<label class="form-label">Email</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.email?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.email">
									{{ errors.email[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.phone" autocomplete="off" @input="form.phone = form.phone.replace(/\D/g, '')" />
								<label class="form-label">Phone Number</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.phone?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.phone">
									{{ errors.phone[0] }}
								</div>
							</div>
							<p class="fs-20 fw-600 mb-20 l-h-n">Clinic’s Basic Information</p>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.clinic_name" autocomplete="off" />
								<label class="form-label">Clinic’s official name. (eg. York Mills Walk-in Clinic)</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.clinic_name?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.clinic_name">
									{{ errors.clinic_name[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.clinic_id" autocomplete="off" />
								<label class="form-label">Clinic ID for EMR login. (eg. ym-clinic) </label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.clinic_id?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.clinic_id">
									{{ errors.clinic_id[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.username"  autocomplete="off" />
								<label class="form-label">Username</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.username?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.username">
									{{ errors.username[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.password" autocomplete="off" />
								<label class="form-label">Password</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.password?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.password">
									{{ errors.password[0] }}
								</div>
							</div>
							<div class="input_box mb-30">
								<input type="text" required class="form-control form-control-lg" v-model="form.pin" @input="form.pin = form.pin.replace(/\D/g, '')"  autocomplete="off" />
								<label class="form-label">Security Code</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form?.pin?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.pin">
									{{ errors.pin[0] }}
								</div>
							</div>
							<p class="agree_agreement_line l-h-n mb-30">By clicking <span class="fst-italic">Continue</span>, you agree to our <a href="javascript:void(0);" class="fs-18 fw-600">Service Agreement.</a></p>
							<div class="mb-30">
								<button class="comman_btn fs-18 w-100 pt-15 pb-15" @click="submitForm">
									Continue <img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
								</button>
							</div>
							<p class="agree_agreement_line l-h-n text-end mb-0">Already have a Verismo account? <span style="cursor: pointer; text-decoration: underline" @click="this.$router.push(`/login`);" class="fs-18 fw-600 ml-10">Login</span></p>
                        </div>
					</div>					
				</div>
			</div>
		</div>
	</section>

	<div class="modal fade" id="contact_us_modal" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl contact_create_modal">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div>
							<h3 class="main_heading mb-40 l-h-n">Contact Us</h3>
							<p class="pt-15 pb-15 fs-16 l-h-n text-center fw-300 mb-35">We’re here to answer any question about our services. Please complete the following form to get connected directly.</p>
							<div class="connected-form-section">
								<div class="row">
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">First Name</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.first_name">
											<div class="invalid-feedback" style="display:block;"
												v-for="(error, index) of v$.contactForm?.first_name?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback" style="display:block;" v-if="errors && errors.first_name">
												{{ errors.first_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Last Name</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.last_name">
											<div class="invalid-feedback" style="display:block;"
												v-for="(error, index) of v$.contactForm?.last_name?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback" style="display:block;" v-if="errors && errors.last_name">
												{{ errors.last_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Email</label>
											<input type="email" class="form-control cursor-text" v-model="contactForm.email">
											<div class="invalid-feedback" style="display:block;"
												v-for="(error, index) of v$.contactForm?.email?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback" style="display:block;" v-if="errors && errors.email">
												{{ errors.first_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Phone</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.phone">
											<div class="invalid-feedback" style="display:block;"
												v-for="(error, index) of v$.contactForm?.phone?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback" style="display:block;" v-if="errors && errors.phone">
												{{ errors.phone[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-12">
										<div class="contact-form mb-40 l-h-n">
											<label class="form-label cursor-text mb-5p">How can we help you?</label>
											<textarea class="form-control" rows="3" v-model="contactForm.message"></textarea>
											<div class="invalid-feedback" style="display:block;"
												v-for="(error, index) of v$.contactForm?.message?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback" style="display:block;" v-if="errors && errors.message">
												{{ errors.message[0] }}
											</div>
										</div>
									</div>
								</div>
								<div class="btn_part text-center">
									<button class="comman_btn fs-18" @click="submitContactForm">Submit <img src="images/loader.gif" v-if="this.loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import {
	required,
	helpers,
	email,
	minLength,
} from "@vuelidate/validators";
import { mapActions, mapGetters } from 'vuex';


export default {
	name: "Sign Up",
	setup() {
		return { v$: useVuelidate() };
	},
	components: {},
	data() {
		return {
			errors: {},
			form: {
				full_name: null,
				email: null,
				phone: null,
				clinic_name: null,
				clinic_id: null,
				username: null,
				password: null,
				pin: null
			},
			contactForm: {
				first_name: null,
				last_name: null,
				email: null,
				phone: null,
				message: null	
			},
			loader: false,
			backEndError: ''
		};
	},
	created() {
		this.$store.state.loader = true;
		setTimeout(() => {
			this.$store.state.loader = false;
		}, 500);
	},
	mounted() { },
	methods: {
		...mapActions('userInfo', ['updateAuthData']),
		openContactUsModel(){
			window.$("#contact_us_modal").modal("show")
		},
		submitForm() {
			console.log("at here");
			this.v$.$validate();
			if (!this.v$.$error) {
				this.loader = true;
				// this.$store.state.loader = true;
				const form = this.form;
				console.log("form data");
				console.log(form);
				axios.post("auth/sign-up", form)
					.then((response) => {
						localStorage.setItem("authToken", response.data.data.token);
						localStorage.setItem("userId", response.data.data.user.id);
						localStorage.setItem("userName", response.data.data.user.username);
						// localStorage.setItem("email", response.data.data.user.email);
						localStorage.setItem("authUserName", response.data.data.user.full_name);
						localStorage.setItem("clinicId", response.data.data.user.clinic_id);
						localStorage.setItem("authUserRole", response.data.data.user.role);
						localStorage.setItem("preference", response.data.data.user.preference);
						localStorage.setItem("userFirstName", response.data.data.user.first_name);
						localStorage.setItem("userLastName", response.data.data.user.last_name);
						
						this.$store.state.userInfo = response.data.data.user
						// this.$store.dispatch('updateAuthData', response.data.data.user);
						this.updateAuthData(response.data.data.user);
						setTimeout(function () {
							location.reload(true);
						}, 100);
					})
					.catch((error) => {
						this.loader = false;
						// this.$store.state.loader = false;
						// this.backEndError = error.response.data.message;

						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
			}
		},
		submitContactForm() {
			this.v$.$validate();
			console.log("this.contactForm");
			console.log(this.contactForm);
		}
	},
	validations() {
		return {
			form: {
				full_name: { required: helpers.withMessage("Please enter Full Name.", required) },
				email: {
					required: helpers.withMessage("Please enter Email.", required),
					email: helpers.withMessage("Please enter a valid Email.", email),
				},
				phone: { required: helpers.withMessage("Please enter Phone.", required) },
				clinic_name: { required: helpers.withMessage("Please enter Clinic Name.", required) },
				clinic_id: { required: helpers.withMessage("Please enter Clinic ID.", required) },
				username: { required: helpers.withMessage("Please enter Username.", required) },
				password: {
					required: helpers.withMessage("Please enter Password.", required),
					minLength: helpers.withMessage("Password must be at least 8 characters long.", minLength(8)),
					pattern: helpers.withMessage(
						"Password must include at least 1 uppercase letter, 1 lowercase letter, and 1 number.",
						/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
					),
				},
				pin: {
					required: helpers.withMessage("Please enter Security Code.", required),
					minLength: helpers.withMessage("Security Code must be at least 4 numbers.", minLength(4)),
					pattern: helpers.withMessage("Security Code must contain only numbers.", /^\d+$/),
				},
			},
			contactForm: {
				first_name: { required: helpers.withMessage("Please enter First Name.", required) },
				last_name: { required: helpers.withMessage("Please enter Last Name.", required) },
				email: {
					required: helpers.withMessage("Please enter Email.", required),
					email: helpers.withMessage("Please enter a valid Email.", email),
				},
				phone: { required: helpers.withMessage("Please enter Phone.", required) },
				message: { required: helpers.withMessage("Please enter Message.", required) },
			}
		};
	},
	computed:{
		...mapGetters('userInfo', ['getAuthData']),
	}
};
</script>
